.footer {
  background-color: #dcdcdc;
  text-align: center;
  margin-top: auto;
  padding: 6px;
}

.book-filter-unit {
  border-radius: 0.3em;
}

.navbar-brand-title {
  margin-left: 20px;
}

.navbar-brand-icon {
  margin-left: 10px;
}

.lecture-unit-card-header {
  background-color: #70c6d8 !important;
  font-weight: bold;
}

.modal-content {
  border-color: #70c6d8 !important;
  border-width: 5px !important;
}

.modal-header-title {
  background-color: black !important;
  color: white;
  font-weight: bold;
}

.modal-body-video {
  position: relative !important;
  padding-top: 56.25% !important; /* 16:9 Aspect Ratio */
}

.youtube-video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-title-description {
  font-size: 14px !important;
}

.text-spinner {
  color: #70c6d8;
  margin: auto;
  text-align: center;
}

.site-upper-body {
  margin-right: 6px;
}

.site-upper-body > h4 {
  color: #70c6d8;
}
